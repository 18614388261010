@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap");

/* Tailwind classes */
@layer components {
  .about-img {
    @apply h-[150px] md:m-0 my-5 mb-10;
  }
  .about-text-right {
    @apply w-full md:text-right text-center;
  }
  .about-text-left {
    @apply w-full md:text-left text-center;
  }
  .about-grid {
    @apply md:grid md:grid-cols-2 flex flex-col-reverse justify-center md:my-16 my-14 m-auto md:w-[70%] md:gap-20;
  }
  .about-grid1 {
    @apply md:grid md:grid-cols-2 flex flex-col justify-center md:my-16 my-14 m-auto md:w-[70%] md:gap-20;
  }
}

[data-aos] {
  pointer-events: none;
}
.aos-animate {
  pointer-events: auto;
}
.aos-init[data-aos][data-aos].aos-animate {
  transform: unset;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
  scroll-behavior: smooth;
}
.App {
  overflow: hidden;
}

body {
  color: #333333;
}
::-webkit-scrollbar {
  width: 10px;
}
::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}
::-webkit-scrollbar-thumb {
  background-color: #0052cc;
  border-radius: 10px;
}
::-webkit-scrollbar-thumb:hover {
  background-color: #0052cc;
  border-radius: 10px;
  cursor: grab;
}

.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

input::-webkit-inner-spin-button,
input::-webkit-outer-spin-button {
  appearance: none;
}

select {
  overflow: hidden;
  overflow: -moz-hidden-unscrollable;
  background: url("../assets/icons/caret-down.svg") no-repeat right white;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.loader {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.auth-shadow {
  box-shadow: -6px -7px 40px rgba(0, 72, 194, 0.15);
}

.auth-shadow-cont {
  box-shadow: 0px 6px 20px rgba(60, 60, 60, 0.15);
}

.download {
  background-image: linear-gradient(rgba(0, 82, 204, 0.5), rgba(0, 82, 204, 0.5)), url("../assets/images/bgImg.webp");
  background-attachment: fixed;
}

.accordion-close {
  max-height: 0;
  overflow: hidden;
  transition: all 0.5s cubic-bezier(0, 1, 0, 1);
}

.accordion-show {
  height: 100%;
  overflow: 9999px;
  transition: all 0.5s cubic-bezier(1, 0, 1, 0);
}

.swiper {
  width: 600px;
  height: 100%;
}

.text-swiper {
  width: auto;
  height: 100%;
}

.swiper-slide {
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  align-items: center;
}

.priceCarousel .swiper-pagination {
  position: relative !important;
}

.text-swiper .swiper-button-prev::after,
.text-swiper .swiper-button-next::after,
.priceCarousel .swiper-button-prev::after,
.priceCarousel .swiper-button-next::after {
  display: none;
}

.swiper-button-prev::after,
.swiper-button-next::after {
  font-size: 30px !important;
  font-weight: bolder !important;
}
.swiper-button-prev,
.swiper-button-next {
  color: white !important;
}
.swiper-pagination-bullet-active {
  width: 25px !important;
  border-radius: 10px !important;
  background-color: white !important;
}

@media screen and (max-width: 767px) {
  .swiper {
    width: 350px;
  }

  .testimony .swiper-button-prev::after,
  .testimony .swiper-button-next::after,
  .testimony .swiper-button-prev,
  .testimony .swiper-button-next {
    display: none;
  }

  header .navlinks {
    display: none;
  }

  .responsive {
    display: block;
    position: absolute;
    background-color: #fff;
    border-bottom: 1px solid;
    transition: 0.3s all linear;
    width: 100%;
    top: 70px;
  }

  .responsive span {
    display: block;
    text-align: left;
    color: #454545;
    width: 100%;
    padding: 15px 12px;
  }
}

.styles_react-code-input-container__tpiKG {
  margin: 0 auto;
}
